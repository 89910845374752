<template>
  <v-card elevation="0" class="pa-0 my-4 price-card">
    <v-container class="pa-0">
      <v-row no-gutters>
        <template v-for="(config, index) in configs">
          <v-col
            class="price-card-item"
            :class="itemTypeClass(config)"
            :cols="config.col ? config.col : 12"
            :key="index"
          >
            <!--     輸入模式       -->
            <div
              v-if="
                ['number-input', 'input', 'selection'].includes(config.type)
              "
              :class="[
                'py-2 px-3 price-card-border cardCBox',
                checkClass(config),
              ]"
              elevation="0"
              tile
            >
              <div class="d-flex justify-space-between align-center">
                <p class="mb-0">{{ config.label }}：</p>
                <div class="d-flex align-center cardInput">
                  <template v-if="config.type === 'input'">
                    <template v-if="config.disabled">
                      <div class="ml-auto">{{ value[config.id] }}</div>
                      <input
                        type="text"
                        hidden
                        :name="config.name"
                        :value="value[config.id]"
                      />
                    </template>

                    <v-text-field
                      v-else
                      dense
                      outlined
                      hide-details
                      class="mx-2"
                      v-model="data[config.id]"
                      :name="config.name"
                      @change="handleChange($event, config)"
                    ></v-text-field>
                  </template>

                  <template v-else-if="config.type === 'number-input'">
                    <template v-if="config.disabled">
                      <div class="ml-auto">{{ value[config.id] }}</div>
                      <input
                        type="text"
                        hidden
                        :name="config.name"
                        :value="value[config.id]"
                      />
                    </template>

                    <number-input
                      v-else
                      class="mx-2"
                      :max="config.max >= 0 ? config.max : -1"
                      v-model="data[config.id]"
                      :fixed="config.fixed"
                      :name="config.name"
                      :rule="config.rule"
                      :control="config.control || false"
                      @change="handleChange($event, config)"
                    ></number-input>
                  </template>

                  <template v-else-if="config.type === 'selection'">
                    <template v-if="config.disabled">
                      <div class="ml-auto">
                        {{ selectionText(config.options, value[config.id]) }}
                      </div>
                      <input
                        type="text"
                        hidden
                        :name="config.name"
                        :value="value[config.id]"
                      />
                    </template>

                    <v-select
                      v-else
                      :items="config.options"
                      v-model="data[config.id]"
                      class="mx-2"
                      dense
                      outlined
                      hide-details
                      :name="config.name"
                      :disabled="config.disabled"
                    ></v-select>
                  </template>

                  <p class="mb-0">{{ config.valueSuffix }}</p>
                </div>
              </div>
            </div>

            <!--     顯示模式       -->
            <div
              v-else
              class="d-flex py-2 px-3 cardCBox justify-start"
              :class="checkClass(config)"
              elevation="0"
              tile
            >
              <div class="label d-flex align-center">
                <!--        checkbox        -->
                <v-checkbox
                  v-if="config.checkbox && config.checkbox.show"
                  v-model="data.checked"
                  dense
                  hide-details
                  class="mt-0"
                ></v-checkbox>
                <div>
                  {{ config.label }}
                  <span v-if="config.colon == false ? false : true">：</span>
                </div>
              </div>
              <div class="value d-flex align-center">
                <span v-if="config.value || config.valueText" class="ml-1">
                  <template v-if="config.type === 'total'">
                    {{ handlePrice(config, config.value) }}
                    {{ config.valueSuffix }}
                  </template>
                  <template v-else-if="config.valueText">
                    {{ config.valueText }}
                  </template>
                  <template v-else>
                    {{ config.value }} {{ config.valueSuffix }}
                  </template>
                </span>
                <!--        favoriteBtn        -->
                <favoriteBtn
                  v-if="config.favorite"
                  :defaultStatus="config.favorite.status"
                  :store-id="config.favorite.meta.storeId"
                  :provider-id="config.favorite.meta.providerId"
                  :product-id="config.favorite.meta.productId"
                  :onlyDelete="true"
                  :callback="config.favorite.callback"
                  class="ml-1"
                ></favoriteBtn>
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import numberInput from "@/modules/base/components/numberInput.vue";
import favoriteBtn from "@/components/buttons/favoriteBtn";
export default {
  components: {
    numberInput,
    favoriteBtn,
  },
  props: {
    value: {
      type: Object,
    },
    configs: {
      type: Array,
    },
    parentIndex: {
      type: Number,
    },
    checkPrice: {
      type: Boolean,
    },
    checkOrderCount: {
      type: Boolean,
    },
  },
  data: () => ({
    data: null,
  }),
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.data = this.$eagleLodash.cloneDeep(this.value);
      },
    },
    data: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.value, this.data)) return;
        this.$emit("input", this.data);
      },
    },
  },
  methods: {
    checkClass(config) {
      let className = typeof config.class != "undefined" ? config.class : "";
      if (
        (!this.checkPrice && ["price"].includes(config.id)) ||
        (!this.checkOrderCount && ["shipping_count"].includes(config.id))
      ) {
        className += " red--text text--darken-2 add-start-before";
      }
      return className;
    },
    selectionText(options, targetValue) {
      const target = options.find((option) => option.value == targetValue);
      if (!target) return null;
      return target.text;
    },
    itemTypeClass(config) {
      if (this.parentIndex % 2 == 0)
        return "blue lighten-5 blue--text text--darken-3";
      return "grey lighten-3";
    },
    numberInputChange(value) {
      this.$emit("numberInputChange", value);
    },
    handleChange(value, config) {
      typeof config.onChange == "function" && config.onChange(value);
    },
    handlePrice(config, price) {
      return this.$fixedPrice(price, config.fixed);
    },
  },
};
</script>


<style lang="sass">
.add-start-before
  .label:first-child
    &::before
      content: '*'

.price-card
  background: #F6F6F8 !important
  border-radius: 5px !important
  &lineT
    border-top: 1px solid #ffffff
  &lineB
    border-bottom: 1px solid #ffffff
  &lineL
    border-left: 1px solid #ffffff
  &lineR
    border-right: 1px solid #ffffff
  &-border
    border: 1px solid #ffffff
    height: 100%
    align-items: center
  &-borderNone
    border: none
  .cardInput
    max-width: 230px
    flex: 1 !important
.linerp-layout
  .price-card-item
    border-radius: 0 0 5px 5px
    &--primary
      color: #ffffff
      background: #FF7D00
    &--gray
      color: #ffffff
      background: #484a4b
.provider-store-layout
  .price-card-item
    border-radius: 0 0 5px 5px
    &--primary
      color: #ffffff
      background: #006DE0
    &--gray
      color: #ffffff
      background: #484a4b
.card-top
  border-radius: 5px 5px 0 0
.card-bottom
  border-radius: 0 0 5px 5px
.border-b
  border-bottom-width: 3px
  border-bottom-style: solid
  border-bottom-color: white
</style>